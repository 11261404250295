<template>
  <div
      class="f6 h-100 material-icons-loaded bg-concord-near-white concord-dark-gray fw4 h-100 mh0 pa0 roboto  ember-application application   pace-done"
      style="height: 100vh; overflow: hidden">
    <div class="h-100 h-100 flex flex-column">
      <div class="flex h-100">
        <contract-setting-left-menu></contract-setting-left-menu>
        <div class="h-100 flex-auto text-align-left">
          <div class="flex flex-column h-100 w-100 pl1 pr4">
            <MetaRightSideHeader>
              <template #title>角色</template>
              <template #bottom-tip>
                角色是一组权限。每个用户都被分配到一个角色，以定义他们在MetaGo中可以执行的操作。无法修改系统角色。创建自定义角色以根据需要配置特定权限。
              </template>
              <template #middle-right><el-button type="primary" @click="gotoPage(null)">添加角色</el-button></template>
            </MetaRightSideHeader>
            <div class="data-container shadow" style="flex: 1">
              <MetaListTable
                  :table-data="roleList"
                  :table-height="tableHeight"
                  @cell-click="gotoPage"
              >
                <el-table-column
                    label="状态"
                    width="150px"
                    fixed="right"
                    align="center"
                >
                  <template #default="scope">
                    <div class=" concord-mid-gray overflow-wrap ph3 ">
                        <span v-if="scope.row.roleType == 0" style="width: 80px;"
                              class="br4 dib f6 fw1 lh-copy pa0 tc v-mid white bg-picton-blue">
                          系统
                        </span>
                      <span v-else style="width: 80px;"
                            class="br4 dib f6 fw1 lh-copy pa0 tc v-mid white bg-soft-green">
                          自定义
                      </span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    label="角色"
                    fixed="right"
                    show-overflow-tooltip
                    prop="name"
                >
                </el-table-column>
                <el-table-column
                    label="描述"
                    fixed="right"
                    prop="description"
                >
                </el-table-column>
                <el-table-column
                    label="用户"
                    fixed="right"
                >
                  <template #default="scope">
                    <div class="flex">
                      <members :members="scope.row.roleUserDTOS" main_placement="bottom" sub_placement="left-start">
                        <template #reference>
                          <div style="display:flex;flex-wrap: wrap">
                            <div v-for="(item,index) in scope.row.roleUserDTOS.slice(0,5)" :key="index" style="line-height: 40px;margin-left: -8px">
                              <user-icon  :url="item.headImgUrl" :name="item.userName" :uid="item.userName"></user-icon>
                            </div>
                            <div v-if="scope.row.roleUserDTOS.length>5" style="line-height: 40px;margin-left: -8px">
                              <user-icon :name="'+'+(scope.row.roleUserDTOS.length-5)" :uid="'count'" :count="2" :size="'small'"></user-icon>
                            </div>
                          </div>
                        </template>
                      </members>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    width="180px"
                    align="center"
                >
                  <template #default="scope">
                    <div class=" concord-mid-gray overflow-wrap ph3">
                        <span v-if="scope.row.defaultRole" style="width: 80px;" class="button-reset fw5 outline-0 ba br1 bg-soft-green b--soft-green white bg-animate hover-bg-dark-green hover-b--dark-green ph2 f6 size-extra-small pointer">
                          默认角色
                        </span>
                      <span v-else style="color: #5280FF" @click.stop="setDefaultRole(scope.row)">设为默认</span>
                    </div>
                  </template>
                </el-table-column>
              </MetaListTable>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

import ContractSettingLeftMenu from "../components/ContractSettingLeftMenu";
import {getAllRoles,
  updateDefaultRoleId,
} from "../api/api";
import Members from "../components/Members";
import UserIcon from "../components/UserIcon";
import MetaListTable from "../components/list/MetaListTable";
import MetaRightSideHeader from "../components/universalUI/MetaRightSideHeader";
import {MetaMessage} from "../util";
import {globalTagClick} from "../assets/js/tag/tag";
export default {
  name: "Role",
  components: {MetaRightSideHeader, ContractSettingLeftMenu,MetaListTable,Members,UserIcon},
  data() {

    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      roleList: [],
      tableHeight: document.body.clientHeight - 150,
      metaMessage:new MetaMessage(),
    }
  },
  mounted() {
    getAllRoles().then(res => {
      if (res.status == 200) {
          this.roleList = res.data.data;
      }else {
        this.metaMessage['error']('角色数据获取失败');
      }
    }).catch(err => {
      console.log(err)
      this.metaMessage['error']("角色数据获取失败");
    })
    window.onresize=()=>{
      this.tableHeight=document.body.clientHeight - 150
    }
  },
  methods: {
    matchPath(path){
      return this.$route.path.indexOf(path)>-1
    },
    gotoPage(row){
      if(row){
        this.$router.push({name:'roleEdit',params:{id:row.id}})
      }else{
        this.$router.push({name:'roleEdit'})
      }
    },
    setDefaultRole(row){
      globalTagClick('role-set-default',{defaultRoleId:row.id});
      updateDefaultRoleId(row.id , this.userInfo.mainSiteTeamId).then(res =>{
        console.log(res);
        if (res.data.code == 0) {
          for (const index in this.roleList) {
            if (this.roleList[index].id == row.id){
              this.roleList[index].defaultRole = true;
            }else {
              this.roleList[index].defaultRole = false;
            }
          }
          this.metaMessage['info']('默认角色设置成功');
        }else {
          this.metaMessage['error']('默认角色设置失败');
        }
      }).catch(err => {
        console.log(err)
        this.metaMessage['error']("默认角色设置失败");
      });
    },
  }
}

</script>

<style scoped>
/*@import url("../assets/css/contract.css");*/
@import url("../assets/css/concord.css");
.data-container {
  text-align: left;

  background: white;
}


.data-dialog {
  text-align: left;

}

.el-dialog__header {
  text-align: left;
}

.addData-btn{
  height: 32px;
  box-sizing: border-box;
  min-height: unset;
  line-height: 6px;
  margin-left: 5px;
}


.stage-index{
  padding-right: 5px;
  border-right: solid 1px #ddd;
  margin-right: 10px;
  height: 20px;
  line-height: 20px;

}


</style>
